import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class PriceIndexData extends BaseModel {
  constructor(id, number, designation, indexDate, indexValue) {
    super(id, number, designation);
    this.indexDate = indexDate;
    this.indexValue = indexValue;
  }
  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'standard_index_data';
  }
  static get listPageRoute() {
    return null;
  }
  static get detailPageRoute() {
    return null;
  }
  static get createInitColumns() {
    return {};
  }
  static get orderKey() {
    return 'index_date';
  }
  get fkName() {
    return PriceIndexData.fkName;
  }
  get apiRoute() {
    return PriceIndexData.apiRoute;
  }
  get detailPageRoute() {
    return PriceIndexData.detailPageRoute;
  }
  get listPageRoute() {
    return PriceIndexData.listPageRoute;
  }
  get createInitColumns() {
    return PriceIndexData.createInitColumns;
  }

  static get listDisplayAttrs() {
    return this.getAttributes(['indexDate', 'indexValue']);
  }

  static get allAttributes() {
    return {
      indexDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'index_date',
        validations: {required},
        tableProperties: {
          header: 'hit-app.standard-index.index-date',
          maxWidth: '1fr',
        },
      }),
      indexValue: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'value',
        validations: {required},
        decimalPrecision: 4,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.standard-index.index-value',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
